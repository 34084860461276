navbar {
    height: 100px;
    width: 100%;
    
    display: flex;

    justify-content: space-around;
    align-items: center;
}

navbar ul {
    display: flex;

    list-style: none;
    align-items: center;
}

.home {
    font-size: 24px;

    text-decoration: none;
    color: var(--night-blue);
}

.link {
    font-size: 15px;
    margin: 0 12px;
    padding: 10px;
    color: black;
    text-decoration: none;
    border: 4px solid var(--beige);
    font-weight: 700;

    display: flex;

    align-items: center;
    
    transition: all 0.2s;
}

.scroll:hover {
    background-color: var(--night-blue);
    color: var(--beige);
}

.redirect:hover {
    color: var(--night-blue);
}

@media (max-width: 640px) {
    navbar {
        justify-content: space-around;
    }

    .scroll { display: none; }
    .primary { display: inline-block; }
}