:root {
  --slate: #1d3557;
  --night-blue: #457b9d;
  --nile-blue: #a8dadc;
  --pale-green: #f1faee;
  --red: #e63946;
  --beige: #fffaf5;
  --tan: #ffeccc;
  --green: #52b69a;
}

.theme-dark {
  --dark-text: #292929;
  --light-text: #f9f8f8;
  --dark-background: #2f4550;
  --light-background: #586f7c;
  --accent: #b8dbd9;
  --button-border: #b8dbd9;
}

.theme-light {
  --slate: #1d3557;
  --night-blue: #457b9d;
  --nile-blue: #a8dadc;
  --pale-green: #f1faee;
  --red: #e63946;
  --beige: #fffaf5;
  --tan: #ffeccc;
  --green: #52b69a;
}

html {
  scroll-behavior: smooth;

  width: 100%;

  margin: 0 auto;
  padding: 0;
}

body {
  background-color: var(--beige);
}

hr {
  width: 80%;
  margin-left: 10%;

  opacity: 0.3;
}

.footer {
  width: 100%;

  background-color: black;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #fff;
}

.footer__links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  width: max-content;
  padding: 0 32px;
}

.footer__link {
  color: #fff !important;
  padding: 0 12px;
}
