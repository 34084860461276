.experienceContainer {
  width: 80%;

  margin-left: 10%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}

.experience {
  width: calc(30%);
}

.role {
  font-size: 35px;
  font-weight: 700;
  color: var(--green);
}

.company {
  font-size: 30px;
  font-weight: 700;

  color: var(--slate);

  text-decoration: none;

  display: inline-block;
  position: relative;
  overflow: hidden;
}

.company::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;

  background-color: var(--slate);
  transition: opacity 300ms, transform 300ms;
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

.company:hover::after {
  transform: translate3d(0, 0, 0);
}

.tasks {
  font-size: 20px;
}

@media (max-width: 1600px) {
  .role {
    font-size: 29px;
  }

  .company {
    font-size: 25px;
  }
}

@media (max-width: 810px) {
  .experienceContainer {
    width: 80%;

    margin-left: 10%;
    margin-right: 10%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .experience {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
  }

  .title {
    font-size: 30px;
  }

  .role {
    font-size: 25px;
  }

  .company {
    font-size: 25px;
  }

  .tasks {
    font-size: 18px;
    text-align: center;
  }
}
