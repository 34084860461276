.aboutContainer {
    width: 80%;

    display: flex;
    flex-direction: row wrap;

    justify-content: space-evenly;
    align-items: center;

    margin-left: 10%;
    margin-right: 10%;
}

.aboutContainer img {
    width: 200px;
    height: 200px;

    border: 10px solid var(--green);
    border-radius: 50%;

    margin-left: 3em;
    margin-top: 3em;
}

.about {
    width: 60%;
    margin-top: 7%;
    margin-bottom: 7%;

    display: flex;
    flex-direction: column;
}

.about h1 {
    font-size: 40px;
    font-weight: 700;
}

.blurb {
    font-size: 20px;
}

.blurb a {
    color: var(--green);

    font-weight: 700;
}

@media (max-width: 640px) {
    .about {
        width: 100%;
        
        display: flex;
        align-items: center;

        text-align: center;
    }

    .aboutContainer img {
        display: none;
    }

    .about h1 {
        font-size: 40px;
    }

    .blurb {
        font-size: 20px;
    }
}