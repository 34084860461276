.projects {
  width: 80%;
  height: 50vh;

  padding-top: 3em;
  padding-bottom: 3em;

  margin-left: 10%;
  margin-right: 10%;

  display: flex;
  flex-direction: column;

  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.project {
  width: 50%;

  padding: 1em;
  text-decoration: none;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 1em;

  border-radius: 5px;

  transition: color 0.2s;
}

.row:nth-child(odd) .project {
  border: 2px solid var(--slate);
  background-color: var(--slate);
  color: #fff;
}

.row:nth-child(even) .project {
  border: 2px solid var(--slate);
  color: var(--slate);
}

.row:nth-child(odd) .project:hover {
  background-color: transparent;
  color: color(var(--slate));
}

.row:nth-child(even) .project:hover {
  background-color: var(--slate);
  color: #fff;
}

/* .project:hover {
  background-color: var(--slate);
  border-color: var(--slate);
  color: var(--tan);
} */

@media (max-width: 640px) {
  .projects {
    width: 80%;
    height: 100%;

    padding-top: 3em;
    padding-bottom: 3em;

    margin-left: 10%;
    margin-right: 10%;

    display: flex;
    flex-direction: column;

    justify-content: center;
  }

  .row {
    width: 80%;

    display: flex;
    flex-direction: column;

    justify-content: center;
  }

  .project {
    width: 100%;
  }
}
